import React from 'react';
import styled from 'styled-components';
import { space, variant } from 'styled-system';

import { bool, node, oneOf, string } from 'prop-types';

import CheckCircle from './icons/CheckCircle';
import CrossCircle from './icons/CrossCircle';
import InfoCircle from './icons/InfoCircle';
import Flag from './icons/Flag';

const StyledLabelMessageWrapper = styled('span')(
  ({ theme }) => ({
    color: theme.colors.dark,
    fontWeight: '500',
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center'
  }),
  ({ theme }) =>
    variant({
      prop: 'type',
      variants: {
        success: {
          color: theme.colors.blue
        },
        complete: {
          color: theme.colors.main
        },
        error: {
          color: theme.colors.red
        },
        hint: {
          color: theme.colors.red
        },
        warning: {
          color: theme.colors.secondary
        }
      }
    })
);

const StyledLabelMessage = styled('span')(
  () => ({
    display: 'inline-block',
    verticalAlign: 'middle'
  }),
  space
);

const getIcon = (type) => {
  switch (type) {
    case 'success':
    case 'complete':
      return <CheckCircle />;
    case 'error':
    case 'warning':
      return <CrossCircle />;
    case 'hint':
      return <Flag />;
    default:
      return <InfoCircle />;
  }
};

const LabelMessage = ({ withIcon, type, children, inputName, ...props }) => {
  return (
    <StyledLabelMessageWrapper type={type} data-input-name={inputName} {...props}>
      {withIcon && !!children && getIcon(type)}
      <StyledLabelMessage withIcon={withIcon} ml={withIcon ? 1 : 0}>
        {children}
      </StyledLabelMessage>
    </StyledLabelMessageWrapper>
  );
};

LabelMessage.propTypes = {
  withIcon: bool,
  children: node,
  type: oneOf(['', 'success', 'complete', 'error', 'warning', 'default', 'hint']),
  inputName: string
};

export default LabelMessage;
