import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const CrossCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.398 7.602a1.185 1.185 0 00-1.683 0L12 10.33 9.285 7.602a1.19 1.19 0 00-1.683 1.683L10.33 12l-2.727 2.715a1.185 1.185 0 000 1.683 1.185 1.185 0 001.683 0L12 13.67l2.715 2.727a1.185 1.185 0 001.683 0 1.185 1.185 0 000-1.683L13.67 12l2.727-2.715a1.185 1.185 0 000-1.683zM20.38 3.62A11.854 11.854 0 103.619 20.381 11.854 11.854 0 1020.381 3.619zm-1.672 15.09A9.483 9.483 0 1121.483 12a9.424 9.424 0 01-2.774 6.71z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default CrossCircle;
