import React from 'react';
import { bool, node, string, arrayOf } from 'prop-types';
import Text from '../../atoms/Text';
import Box from '../../atoms/Box';
import QuestionCircle from '../../atoms/icons/QuestionCircle';
import LabelMessage from '../../atoms/LabelMessage';
import styled from 'styled-components';
import { space } from 'styled-system';

const StyledInputContainer = styled('div')(
  ({ theme, inline }) => ({
    display: inline ? 'inline-flex' : 'block',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    marginBottom: theme.space[3],
    // For "classic" inputs
    '& input ~ label,& textarea ~ label': {
      transition: 'transform .3s ease',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0
    },
    '& :focus ~ label': {
      transform: 'translateX(5px)'
    },
    // For "custom" inputs
    '& [data-labelizable="true"] ~ label': {
      transition: 'transform .3s ease',
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      left: 0
    },
    '& [data-labelizable="true"][data-focus="true"] ~ label': {
      transform: 'translateX(5px)'
    }
  }),
  space
);

const StyledLabel = styled(Text)(() => ({
  position: 'absolute',
  fontWeight: 800,
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  top: 0,
  left: 0
}));

const StyledSuffix = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: theme.space[2],
  top: '38px',
  fontWeight: 600
}));

const Labelize = React.forwardRef(
  ({ inputName, inline, showError, errorMessage, errorMessages, label, children, tooltip, suffix, ...props }, _ref) => {
    const state = errorMessage ? 'error' : 'default';
    return (
      <StyledInputContainer inline={inline}>
        <StyledLabel tag="label" styleName="wLabelPrimary">
          <span>{label}</span>
          {tooltip && (
            <Box display="inline-block" pl={1} pt="2px" height="20px" className="tooltip" data-text={tooltip}>
              <QuestionCircle color="greyDark" />
            </Box>
          )}
        </StyledLabel>
        {suffix && <StyledSuffix>{suffix}</StyledSuffix>}
        {children && React.cloneElement(children, { ...props, state })}
        {showError && errorMessage && (
          <LabelMessage type="error" withIcon={true} inputName={inputName}>
            {errorMessage}
          </LabelMessage>
        )}
        {showError &&
          errorMessages.map((errorMessage, index) => (
            <LabelMessage key={`${errorMessage}-${index}`} type="error" withIcon={true} inputName={inputName}>
              {errorMessage}
            </LabelMessage>
          ))}
      </StyledInputContainer>
    );
  }
);

Labelize.defaultProps = {
  showError: true,
  errorMessages: []
};
Labelize.propTypes = {
  children: node,
  errorMessage: string,
  errorMessages: arrayOf(string),
  label: string,
  showError: bool,
  inline: bool,
  inputName: string,
  suffix: string,
  tooltip: string
};

export default Labelize;
