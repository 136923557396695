import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const QuestionCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.148 15.948c-.052.057-.1.117-.144.18a.912.912 0 00-.108.216.768.768 0 00-.072.216c-.006.08-.006.16 0 .24-.004.157.029.314.096.456.11.301.347.539.648.648.29.128.622.128.912 0a1.08 1.08 0 00.648-.648 1.2 1.2 0 00.072-.456 1.2 1.2 0 00-2.052-.852zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0zm0 21.6a9.6 9.6 0 110-19.2 9.6 9.6 0 010 19.2zM12 6a3.6 3.6 0 00-3.12 1.8A1.2 1.2 0 1010.956 9 1.2 1.2 0 0112 8.4a1.2 1.2 0 110 2.4 1.2 1.2 0 00-1.2 1.2v1.2a1.2 1.2 0 102.4 0v-.216A3.6 3.6 0 0012 6z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default QuestionCircle;
