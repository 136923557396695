import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Flag = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 3a1 1 0 01.293-.707c.22-.22.614-.483 1.21-.721C6.407 1.21 7.564 1 9 1c1.417 0 2.383.276 4.371 1.072C15.133 2.776 15.917 3 17 3c1.189 0 2.094-.165 2.754-.428.341-.137.508-.249.539-.28C20.923 1.663 22 2.11 22 3v12a1 1 0 01-.293.707c-.22.22-.614.483-1.21.721-.903.362-2.06.572-3.497.572-1.417 0-2.383-.276-4.371-1.072C10.867 15.224 10.083 15 9 15c-1.189 0-2.094.165-2.754.428a4.09 4.09 0 00-.247.108L6 22a1 1 0 01-2 0zm5 0c-1.189 0-2.094.165-2.754.428A4.094 4.094 0 006 3.536v9.86C6.82 13.143 7.814 13 9 13c1.417 0 2.383.276 4.371 1.072 1.762.704 2.546.928 3.629.928 1.189 0 2.094-.165 2.754-.428.095-.039.177-.075.246-.108v-9.86c-.82.253-1.814.396-3 .396-1.417 0-2.383-.276-4.371-1.072C10.867 3.224 10.083 3 9 3z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Flag;
