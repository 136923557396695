import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const CheckCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.264 8.148l-5.148 5.16-1.98-1.98a1.2 1.2 0 10-1.692 1.692l2.82 2.832a1.2 1.2 0 001.692 0l6-6a1.2 1.2 0 10-1.692-1.704zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0zm0 21.6a9.6 9.6 0 110-19.2 9.6 9.6 0 010 19.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default CheckCircle;
