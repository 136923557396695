import React from 'react';
import styled from 'styled-components';
import { variant, color, border } from 'styled-system';
import { transparentize } from 'polished';
import { inputPropTypesObject } from '../../elements/form/decorators';
import { oneOf } from 'prop-types';

const StyledInput = styled('input')(
  ({ theme, inline, block, type }) => ({
    width: block ? '100%' : 'auto',
    height: '40px',
    display: 'inline-block',
    outline: 'none',
    lineHeight: '22px',
    borderRadius: theme.radii[2],
    paddingLeft: theme.space[2],
    paddingRight: theme.space[2],
    paddingTop: type === 'date' ? theme.space[1] : 0,
    marginTop: inline ? 0 : '28px',
    border: 'none',
    boxShadow: `0 0 0 1px ${theme.colors.greyVerylight} inset`,
    color: theme.colors.dark,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.greyVerylight,
    transition: 'box-shadow .3s ease',
    appearance: 'none',
    '&:disabled': {
      boxShadow: `0 0 0 2px ${theme.colors.greyVerylight} inset`,
      backgroundColor: theme.colors.greyVerylight,
      cursor: 'not-allowed',
      opacity: '0.5'
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.colors.greyLight} inset`
    },
    '&::placeholder': {
      color: theme.colors.grey
    },
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0
    }
  }),
  ({ theme }) =>
    variant({
      prop: 'state',
      variants: {
        success: {
          boxShadow: `0 0 0 2px ${theme.colors.blue} inset`,
          backgroundColor: transparentize(0.9, theme.colors.blue),
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.blue} inset`
          }
        },
        error: {
          boxShadow: `0 0 0 2px ${theme.colors.red} inset`,
          backgroundColor: transparentize(0.9, theme.colors.red),
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.red} inset`
          }
        }
      }
    }),
  ({ theme }) =>
    variant({
      prop: 'styleName',
      variants: {
        white: {
          boxShadow: `0 0 0 1px ${theme.colors.greyVerylight} inset`,
          backgroundColor: theme.colors.white
        }
      }
    }),
  color,
  border
);

const InputField = React.forwardRef((props, ref) => {
  return <StyledInput ref={ref} {...props} aria-label={props.name} />;
});

InputField.defaultProps = {
  type: 'text'
};

InputField.propTypes = {
  ...inputPropTypesObject,
  state: oneOf(['success', 'error', 'default'])
};

export default InputField;
